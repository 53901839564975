enum State {
  /**
   * The player is stopped, with none of the paths shown.
   */
  Stopped = 'stopped',
  
  /**
   * The player is paused... which works pretty much how you would expect it to.
   */
  Paused = 'paused',
  
  /**
   * The player is playing. Because that's what true players do.
   */
  Playing = 'playing',
  
  /**
   * All paths have been shown, and the SVG will stay like that until either
   * {@link SVGPathPlayer#stop} or {@link SVGPathPlayer#play} are called.
   */
  Done = 'done',
  
  /**
   * When play has reached the end of {@link SVGPathPlayer#paths}, but the
   * instance is waiting for more to load.
   *
   * This state is set in {@link SVGPathPlayer#showNextPath}, and checked when a
   * load completes - if present, showing paths in resumed.
   */
  Buffering = 'buffering',
};


class AsyncForEach<T> {
  array: T[];
  block: (entry: T) => void;
  index: number;
  protected boundNext: () => void;
  
  promise: Promise<void>;
  protected resolve: () => void;
  
  constructor (array: T[], block: (entry: T) => void ) {
    this.array = array;
    this.block = block;
    this.index = 0;
    this.boundNext = this.next.bind( this );
    
    this.resolve = () => {};
    this.promise = new Promise( resolve => this.resolve = resolve )
    
    setTimeout( this.boundNext );
  }
  
  protected next () {
    // Check bounds
    if (this.index >= this.array.length) {
      this.resolve();
      return; // Done!
    }
    
    // Call the block
    this.block( this.array[ this.index ] );
    
    // Increment the index 
    this.index += 1;
    
    // ...and queue up the next iteration
    setTimeout( this.boundNext );
  }
} // class AsyncForEach


class SVGPathPlayer {
  
  readonly element: SVGSVGElement;
  readonly extraPathsURLs: string[];
  
  frameTimeoutMS: number;
  
  currentPathIndex: number;
  paths: SVGPathElement[];
  
  protected _state: State;
  
  extraPathsRequest: XMLHttpRequest | null;
  currentExtraPathsURLsIndex: number;
  
  protected _domParser: DOMParser | null;
  
  lastPathMS: number | null;
  
  constructor ({
    element,
    extraPathsURLs = [],
    frameTimeoutMS,
  }: {
    element:        SVGSVGElement,
    extraPathsURLs: string[],
    frameTimeoutMS:    number,
  }) {
    this.element        = element;
    this.extraPathsURLs = extraPathsURLs;
    this.frameTimeoutMS = frameTimeoutMS;
    
    this.currentPathIndex = -1;
    this.paths = Array.from( this.element.getElementsByTagName( 'path' ) );
    
    this._state = State.Stopped;
    
    // Not requesting extra paths right now
    this.extraPathsRequest = null;
    
    this.currentExtraPathsURLsIndex = -1;
    
    this._domParser = null;
    
    this.lastPathMS = null;
  }
  
  
  get state (): State {
    return this._state;
  }
  
  
  get domParser (): DOMParser {
    if (this._domParser === null) {
      this._domParser = new DOMParser();
    }
    
    return this._domParser;
  }
  
  
  isPlaying (): boolean {
    return this.state === State.Playing;
  }
  
  isDone (): boolean {
    return this.state === State.Done;
  }
  
  isStopped (): boolean {
    return this.state === State.Stopped;
  }
  
  isBuffering (): boolean {
    return this.state === State.Buffering;
  }
  
  isPaused (): boolean {
    return this.state === State.Paused;
  }
  
  
  pause (): boolean {
    if (this.isPaused()) {
      return false;
    }
    
    this._state = State.Paused;
    
    return true;
  }
  
  
  play (): boolean {
    if (this.isPlaying()) {
      console.log( "%s already playing", this );
      return false;
    }
    
    if (this.extraPathsURLs.length > 0 &&
        this.currentExtraPathsURLsIndex === -1) {
      this.loadNextExtraPaths();
    }
    
    if (this.isDone()) {
      console.log( "%s is done, restarting to play...", this );
      this.stop();
    }
    
    this._state = State.Playing;
    
    this.showNextPath();
    
    return true;
  }
  
  
  stop (): boolean {
    if (this.isStopped()) {
      console.log( "%s is already stopped, noop.", this );
      return false;
    }
    
    this.paths.forEach( path => {
      path.setAttribute( 'style', 'display: none;' );
    });
    
    this._state = State.Stopped;
    
    return true;
  }
  
  
  protected showNextPath (): void {
    if (!this.isPlaying()) {
      return;
    }
    
    const index = this.currentPathIndex + 1;
    
    if (process.env.NODE_ENV === 'development') {
      const nowMS = (new Date()).getTime();
      
      if (this.lastPathMS !== null) {
        const realTimeout = nowMS - this.lastPathMS;
        if (realTimeout > this.frameTimeoutMS * 1.2) {
          console.log(
            "Real timeout: %ims at path %i",
            realTimeout,
            index
          );
        }
      }
      
      this.lastPathMS = nowMS;
    }  
    
    if (index < this.paths.length) {
      const path = this.paths[ index ];
      path.removeAttribute( 'style' );
      
      this.currentPathIndex = index;
      
      setTimeout( this.showNextPath.bind( this ), this.frameTimeoutMS );
      
      return;
    }
    
    if (this.isLoadingExtraPaths()) {
      this._state = State.Buffering;
      return;
    }
    
    this._state = State.Done;
  } // #showNextPath
  
  
  isLoadingExtraPaths (): boolean {
    return this.extraPathsRequest !== null;
  }
  
  
  protected loadNextExtraPaths (): void {    
    const index = this.currentExtraPathsURLsIndex + 1;
    
    if (index >= this.extraPathsURLs.length) {
      // Loaded all of them, nothing to do
      return;
    }
    
    this.currentExtraPathsURLsIndex = index;
    
    const url = this.extraPathsURLs[ index ];
    
    const request = new XMLHttpRequest();
    this.extraPathsRequest = request;
    request.open( 'GET', url, true );
    request.responseType = 'text';
    
    request.onload = () => {
      this.handleExtraPathsLoad( request );
    };
    
    // setTimeout( request.send.bind( request ), 0 );
    request.send();
    // console.log( "sent" );
  }
  
  
  handleExtraPathsLoad (request: XMLHttpRequest ) {
    const startedAt = (new Date()).getTime();
    
    new AsyncForEach(
        request.responseText.split( "\n" ),
        line => {
          
          const doc = this.domParser.parseFromString( line, 'image/svg+xml' );
          const docPath = doc.children[ 0 ];
          
          if (docPath === null) {
            console.error( "Failed to parse SVG path line '%s'", line );
            return;
          }
        
          const path = document.createElementNS(  "http://www.w3.org/2000/svg",
                                                  "path" );
          
          Array.from( docPath.attributes ).forEach( attribute => {
            path.setAttribute( attribute.name, attribute.value );
          });
          
          path.setAttribute( 'style', 'display: none;' );
          
          // const parent = this.paths[ this.paths.length - 1 ].parentNode;
          const parent = this.element.getElementsByTagName( 'g' )[ 0 ];
          
          if (parent === null) {
            throw new Error(
              `Path ${ this.paths[ this.paths.length - 1 ] } has no parent!`
            );
          }
          
          parent.appendChild( path );
          this.paths.push( path );
          
        }
    ).promise.then( () => {
        if (this.extraPathsRequest === request) {
          this.extraPathsRequest = null;
        }
        
        this.loadNextExtraPaths();
    
        if (this.isBuffering()) {
          this._state = State.Playing;
          this.showNextPath();
        }
      });
    
  } // #handleExtraPathsLoad
  
  
  toString () {
    return this.element.toString();
  }
  
} // class SVGPathPlayer


export default SVGPathPlayer;
