// Imports
// ===========================================================================

import ImageLoader from './ImageLoader';
import SVGPathPlayer from './SVGPathPlayer';

import me1200x676URL  from '../images/backdrop/me/me@1200x676.jpg';
import me1800x1014URL from '../images/backdrop/me/me@1800x1014.jpg';
import me3000x1688URL from '../images/backdrop/me/me@3000x1688.jpg';

import shapesPaths1 from '../images/backdrop/loading/2019.04.04-2500/shapes-paths.1.svg';
import shapesPaths2 from '../images/backdrop/loading/2019.04.04-2500/shapes-paths.2.svg';
import shapesPaths3 from '../images/backdrop/loading/2019.04.04-2500/shapes-paths.3.svg';
import shapesPaths4 from '../images/backdrop/loading/2019.04.04-2500/shapes-paths.4.svg';


// Definitions
// ===========================================================================

class Loader {
  
  // Constants
  // =========================================================================
  
  static readonly IMAGES = [
    { url: me1200x676URL,   width: 1200, height: 676 },
    { url: me1800x1014URL,  width: 1800, height: 1014 },
    { url: me3000x1688URL,  width: 3000, height: 1688  },
  ];
  
  static readonly WH_RATIO = 6000 / 3376;
  
  static readonly SHAPES_TIMEOUT_MS = 15;
  
  static readonly DEFAULT_SHAPES_MIN_PLAY_MS = 4800;
  
  
  // Class Methods
  // =========================================================================
  
  static measureViewport () {
    const width = document.documentElement.clientWidth;
    const height = window.innerHeight;
    
    return {
      width,
      height,
      whRatio: width / height,
    }
  }
  
  
  static requireById<T extends Element> (id: string): T {
    const element = document.getElementById( id );
    
    if (element === null) {
      throw new Error( `Element #${ id } not found!` );
    }
    
    // The `<unknown>` cast is needed because `document.getElementById` has 
    // return type {@link HTMLElement}... which seems to be a mistake because 
    // it *can* return {@link SVGSVGElement}, and does in one of our cases.. :/
    return <T><unknown>element;
  }
  
  
  // Instance Properties
  // =========================================================================
  
  readonly div: HTMLDivElement;
  readonly shapes: SVGSVGElement;
  readonly photo: HTMLImageElement;
  
  readonly resizeListener: () => void;
  
  readonly photoLoader: ImageLoader;
  readonly shapesPlayer: SVGPathPlayer;
  
  readonly startedAt: Date;
  
  readonly shapesMinPlayMS: number;
  
  
  // Construction
  // =========================================================================
  
  constructor ({
    shapesMinPlayMS = Loader.DEFAULT_SHAPES_MIN_PLAY_MS,
  }: {
    shapesMinPlayMS?: number,
  } = {}) {
    // Start booting the app asynchronously
    import( '../boot' );
    
    // Store references to elements we're going to use
    this.div = Loader.requireById<HTMLDivElement>( 'backdrop' );
    this.shapes = Loader.requireById<SVGSVGElement>( 'backdrop-shapes' );
    this.photo = Loader.requireById<HTMLImageElement>( 'backdrop-photo' );
    
    // Do an initial resize and bind window resizes to do them
    this.resize();
    this.resizeListener = this.resize.bind( this );
    window.addEventListener( 'resize', this.resizeListener );
    
    this.photoLoader = new ImageLoader({
      url: me1800x1014URL,
      onload: this.handlePhotoLoad.bind( this ),
      onprogress: this.handlePhotoProgress.bind( this ),
    });
    
    this.shapesPlayer = new SVGPathPlayer({
      element: this.shapes,
      frameTimeoutMS: Loader.SHAPES_TIMEOUT_MS,
      extraPathsURLs: [
        shapesPaths1,
        shapesPaths2,
        shapesPaths3,
        shapesPaths4,
      ],
    });
    
    this.shapesPlayer.play();
    
    this.startedAt = new Date();
    
    this.shapesMinPlayMS = shapesMinPlayMS;
    
  } // #constructor
  
  
  // Instance Methods
  // =========================================================================
  
  showPhotoTimeout (): number {
    const nowMS = (new Date()).getTime();
    const delta = nowMS - this.startedAt.getTime();
    
    if (delta >= this.shapesMinPlayMS) {
      return 0;
    }
    
    return this.shapesMinPlayMS - delta;
  }
  
  
  handlePhotoProgress (photoLoader: ImageLoader): void {
    // console.log( "Progress: %s%%", photoLoader.loadedPercent() );
  }
  
  
  handlePhotoLoad (photoLoader: ImageLoader): void {
    setTimeout(
      () => {
        this.photo.setAttribute( 'src', photoLoader.loadedURL() );
        this.photo.setAttribute( 'visibility', 'visible' );
        this.photo.className += ' fade-in';
        setTimeout( () => { this.shapesPlayer.pause() }, 5000 );
      },
      this.showPhotoTimeout(),
    );
  }
  
  
  resize (): void {
    const viewport = Loader.measureViewport();
    
    const width = (
      viewport.whRatio >= Loader.WH_RATIO ?
      viewport.width :
      Math.floor( viewport.height * Loader.WH_RATIO )
    );
    
    const height = (
      width === viewport.width ?
      Math.floor( width / Loader.WH_RATIO ) :
      viewport.height
    );
    
    const left = (
      width === viewport.width ?
      0 :
      Math.floor((width - viewport.width) / -2)
    );
    
    this.div.setAttribute(
      'style',
      // Why was this working?!
      // `width: ${ width }px; height: ${ viewport.height }px; left: ${ left }px`
      `width: ${ viewport.width }px; height: ${ viewport.height }px;`
    );
    
    this.photo.setAttribute(
      'style',
      `width: ${ width }px; height: ${ height }px; left: ${ left }px`
    );
    
    this.shapes.setAttribute( 'width', width.toString() );
    this.shapes.setAttribute( 'height', height.toString() );
    this.shapes.setAttribute( 'style', `left: ${ left }px;` );
    
  } // #resize
  
} // class Loader


// Exports
// ===========================================================================

export default Loader;
