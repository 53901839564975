
class ImageLoader {
  
  // Constants
  // =========================================================================
  
  static readonly DEFAULT_MIME_TYPE = 'image/jpeg';
  
  
  // Class Methods
  // =========================================================================
  
  static getMimeType (request: XMLHttpRequest): string {
    const contentType = request.getResponseHeader( "Content-Type" );
    
    if (contentType === null) {
      console.warn( "Could not extract mime type from %s",
                    request.getAllResponseHeaders() );
      return this.DEFAULT_MIME_TYPE;
    }
    
    if (contentType.indexOf( ";" ) !== -1) {
      return contentType.split( ';', 2 )[ 1 ];
    }
    
    return contentType;
  }
  
  
  // Instance Properties
  // =========================================================================
  
  readonly url: string;
  readonly request: XMLHttpRequest;
  
  blob: Blob | null;
  blobURL: string | null;
  
  onload: (imageLoader: ImageLoader) => void;
  onprogress: (imageLoader: ImageLoader) => void;
  
  loaded: number;
  total: number | undefined;
  
  startAt: Date;
  
  progress: { date: Date, loaded: number }[];
  
  // Construction
  // =========================================================================
  
  constructor ({
    url,
    onload,
    onprogress,
  }: {
    url: string
    onload: (imageLoader: ImageLoader) => void,
    onprogress: (imageLoader: ImageLoader) => void,
  }) {
    this.url = url;
    this.onload = onload;
    this.onprogress = onprogress;
    
    this.blob = null;
    this.blobURL = null;
    
    this.loaded = 0;
    this.total = undefined;
    
    this.progress = [
      {
        date: new Date(),
        loaded: 0,
      }
    ]
    
    this.request = new XMLHttpRequest();
    this.request.open( 'GET', this.url, true );
    this.request.responseType = 'arraybuffer';
    this.request.onload = this.handleRequestLoad.bind( this );
    this.request.onprogress = this.handleRequestProgress.bind( this );
    
    this.startAt = new Date();
    this.request.send();
  }
  
  
  // Instance Methods
  // ===========================================================================
  
  loadedURL (): string {
    if (this.blobURL === null) {
      throw new Error( `Not done loading from ${ this.url }` );
    }
    
    return this.blobURL;
  }
  
  
  handleRequestLoad ( event: ProgressEvent ): void {
    const mimeType = ImageLoader.getMimeType( this.request );
    
    this.blob = new Blob( [ this.request.response ], {type: mimeType} );
    this.blobURL = window.URL.createObjectURL( this.blob );
    
    this.onload( this );
  }
  
  
  handleRequestProgress (event: ProgressEvent): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent/lengthComputable
    if (!event.lengthComputable) {
      console.warn( "Length not computable loading image at %s", this.url );
      return;
    }
    
    this.loaded = event.loaded;
    this.total = event.total;
    
    this.progress.push({
      date: new Date(),
      loaded: event.loaded,
    });
    
    this.onprogress( this );
  }
  
  
  loadedRatio (): number | undefined {
    if (this.total === undefined || !(this.total > 0)) {
      return undefined;
    }
    
    return this.loaded / this.total;
  }
  
  
  loadedPercent (): number | undefined {
    const ratio = this.loadedRatio();
    
    if (ratio === undefined) {
      return undefined;
    }
    
    return Math.floor( ratio * 100 );
  }
  
  
} // class ImageLoader


export default ImageLoader;
